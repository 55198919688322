import React from 'react';
import { Ctx } from 'boardgame.io';

import { ITCGCard, styles } from './itcgCard';
import { GameState } from './game';
import { Location } from './target';
import { isCharacter } from './card';
import { nullMove } from './moves';
import { getCardLocation } from './utils';

interface HighlightProp {
  state: GameState;
  ctx: Ctx;
}

const highlightStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#c9def2',
  width: '21%',
  border: 'groove',
  fontSize: '1.5vw',
  color: 'white',
  textShadow: '2px 0 5px #000, -2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000',
  textAlign: 'center',
};

export class ITCGHighlight extends React.Component<HighlightProp> {
  render() {
    const stack = this.props.state.stack;

    if (!stack || stack.activeDecisions[0].action === 'attack') return null;

    const sourceEffect = stack.activeDecisions[0].opts?.source
      ? stack.activeDecisions[0].opts!.source!
      : false;
    if (!sourceEffect) return null;

    const cardLoc = getCardLocation(this.props.state, this.props.ctx, sourceEffect.key);
    const activationPos =
      this.props.state.player[this.props.ctx.currentPlayer].activationPos;

    let topStyle: styles = 'highlightShadeStyle';
    let botStyle: styles = 'highlightShadeStyle';
    let char2Style: styles = 'highlightShadeStyle';
    let charBotStyle: styles = 'highlightShadeStyle';

    if (isCharacter(sourceEffect) && activationPos <= 3) {
      switch (activationPos) {
        case 1:
          botStyle = 'expandStyle';
          break;
        case 2:
          char2Style = 'expandStyle';
          break;
        case 3:
          charBotStyle = 'expandStyle';
          break;
      }
    } else if (cardLoc === Location.CharAction) {
      botStyle = 'expandStyle';
    } else {
      topStyle = 'expandStyle';
    }

    return (
      <div style={highlightStyle}>
        <p>Current Effect</p>
        <ITCGCard
          card={sourceEffect}
          location={Location.Field}
          move={nullMove}
          styles={[topStyle]}
          skill0={[botStyle]}
          skill1={[char2Style]}
          skill2={[charBotStyle]}
        />
      </div>
    );
  }
}
