import { CardName } from './cards';
import { TriggerNames } from './trigger';

import buff from './images/buff.png';
import focus from './images/focus.png';
import meditation from './images/meditation.png';
import steadyhand from './images/steadyhand.png';
import teleport from './images/teleport.png';
import mpeater from './images/mpeater.png';

export const TriggerIcons: Partial<Record<TriggerNames, string>> = {
  BuffAllTrigger: buff,
  FocusTrigger: focus,
  MeditationTrigger: meditation,
  SteadyHandTrigger: steadyhand,
};

export const StateIcons: Partial<Record<CardName, string>> = {
  teleport: teleport,
  mpeater: mpeater,
};
