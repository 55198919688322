import { Ctx, PlayerID } from 'boardgame.io';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { StateIcons, TriggerIcons } from './itcgIcon';
import { ITCGCard } from './itcgCard';
import { GameState } from './game';
import { getCard, getCardAtLocation, getCardLocation } from './utils';

interface FieldProps {
  G: GameState;
  ctx: Ctx;
  player: PlayerID;
  stage: string;
}

const fieldStyle: React.CSSProperties = {
  display: 'flex',
  flex: '1',
  alignItems: 'center',
  maxHeight: '10%',
  overflow: 'auto',
  paddingLeft: '1%',
};

const activeStyle: React.CSSProperties = {
  ...fieldStyle,
  backgroundColor: '#c9def2aa',
};

const inactiveStyle: React.CSSProperties = {
  ...fieldStyle,
  backgroundColor: '#a0b1c150',
};

export class ITCGBuffs extends React.Component<FieldProps> {
  render() {
    const triggers = this.props.G.triggers
      .filter((trig) => {
        return (
          trig.owner === this.props.player &&
          Object.keys(TriggerIcons).includes(trig.name)
        );
      })
      .map((trig) => {
        const cardLocation = getCardLocation(
          this.props.G,
          this.props.ctx,
          trig.cardOwner
        );

        const card = getCardAtLocation(
          this.props.G,
          this.props.ctx,
          cardLocation,
          trig.cardOwner
        );

        return (
          <div
            data-tooltip-id="expanded-card"
            data-tooltip-html={renderToStaticMarkup(
              <ITCGCard
                move={() => {}}
                location={cardLocation}
                styles={['expandStyle']}
                skill0={['expandStyle']}
                card={card}
                key={card.key}
              />
            )}
          >
            <img className={'no-border'} src={TriggerIcons[trig.name]} />
          </div>
        );
      });

    const states = this.props.G.state
      .filter((state) => {
        const card = getCard(this.props.G, this.props.ctx, state.owner);
        return (
          state.player === this.props.player &&
          Object.keys(StateIcons).includes(card.canonicalName)
        );
      })
      .map((state) => {
        const cardLocation = getCardLocation(this.props.G, this.props.ctx, state.owner);

        const card = getCardAtLocation(
          this.props.G,
          this.props.ctx,
          cardLocation,
          state.owner
        );

        return (
          <div
            data-tooltip-id="expanded-card"
            data-tooltip-html={renderToStaticMarkup(
              <ITCGCard
                move={() => {}}
                location={cardLocation}
                styles={['expandStyle']}
                skill0={['expandStyle']}
                card={card}
                key={card.key}
              />
            )}
          >
            <img className={'no-border'} src={StateIcons[card.canonicalName]} />
          </div>
        );
      });

    const stage = this.props.stage;
    const parsedStyle = stage && stage !== 'unactive' ? activeStyle : inactiveStyle;
    return <div style={parsedStyle}>{[...triggers, ...states]}</div>;
  }
}
